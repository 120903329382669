import { Box, TextField } from "@mui/material";
import HError from "./HError";

interface Props {
  variant?: "outlined" | "filled" | "standard";
  name: string;
  // id?: string;
  label?: string;
  type?:
    | "text"
    | "number"
    | "password"
    | "email"
    | "file"
    | "date"
    | "month"
    | "color";
  placeholder?: string;
  size?: "small" | "medium" | "large" | null;
  touched?: any;
  errors?: any;
  onChange?: any;
  value?: any;
  maxLength?: any;
  inputstyle?: {};
  disabled?: any;
  styleObj?: any;
  isMulitiline?: boolean;
  isRequired?: boolean;
  inputRef?: any;
}

export default function HTextField({
  variant = "outlined",
  styleObj,
  name,
  // id = "",
  label = "",
  type = "text",
  placeholder = "",
  size = "medium",
  value,
  onChange,
  errors,
  touched,
  maxLength,
  inputstyle,
  disabled,
  isMulitiline,
  isRequired,
  inputRef,
}: Props) {
  const inputFieldStyele = (type: string) => {
    switch (type) {
      case "standard":
        return {};
      default:
        return {
          padding: "16.5px 14px !important",
        };
    }
  };

  const customStyle = {
    width: "100% !important",
    "& .MuiInputLabel-filled": {
      top: "-3px",
      "&.Mui-focused": {
        top: "-5px!important",
      },
    },

    "& .MuiInputBase-input": {
      ...inputFieldStyele(variant),
    },
    "& .MuiInputLabel-asterisk": {
      color: "red",
      fontSize: "1rem",
    },
  };
  const multilineStyle = {
    width: "100% !important",
    "& .MuiInputLabel-filled": {
      top: "-3px",
      "&.Mui-focused": {
        top: "-5px!important",
      },
    },

    "& .MuiInputBase-input": {
      border: "none !important",
    },
    "& .MuiInputLabel-asterisk": {
      color: "red",
      fontSize: "1rem",
    },
  };
  return (
    <div style={styleObj} className="h_input_field">
      {size === "small" || size === "medium" ? (
        <>
          {errors[name] && touched[name] ? (
            <TextField
              id={name}
              error
              variant={variant}
              name={name}
              label={
                label && isRequired ? (
                  <Box component="span">
                    {label}{" "}
                    <Box component="span" sx={{ color: "red" }}>
                      *
                    </Box>
                  </Box>
                ) : (
                  label
                )
              }
              type={type}
              placeholder={placeholder}
              size={size}
              onChange={(e) => onChange(e.target.value, e)}
              value={value}
              color="primary"
              sx={customStyle}
              inputProps={{ maxLength, style: inputstyle }}
              disabled={disabled}
              // required={isRequired}
              inputRef={inputRef}
            />
          ) : (
            <TextField
              id={name}
              variant={variant}
              name={name}
              label={
                isRequired ? (
                  <Box component="span">
                    {label}{" "}
                    <Box component="span" sx={{ color: "red" }}>
                      *
                    </Box>
                  </Box>
                ) : (
                  label
                )
              }
              type={type}
              placeholder={placeholder}
              size={size}
              onChange={(e) => onChange(e.target.value, e)}
              value={value}
              color="primary"
              sx={customStyle}
              inputProps={{ maxLength, style: inputstyle }}
              disabled={disabled}
              // required={isRequired}
              inputRef={inputRef}
            />
          )}
        </>
      ) : isMulitiline ? (
        <TextField
          id={name}
          variant={variant}
          name={name}
          label={
            isRequired ? (
              <Box component="span">
                {label}{" "}
                <Box component="span" sx={{ color: "red" }}>
                  *
                </Box>
              </Box>
            ) : (
              label
            )
          }
          multiline
          rows={4}
          onChange={(e) => onChange(e.target.value, e)}
          value={value}
          color="primary"
          sx={multilineStyle}
          inputProps={{ maxLength, style: inputstyle }}
          disabled={disabled}
          // required={isRequired}
          inputRef={inputRef}
        />
      ) : (
        <TextField
          id={name}
          variant={variant}
          name={name}
          label={
            isRequired ? (
              <Box component="span">
                {label}{" "}
                <Box component="span" sx={{ color: "red" }}>
                  *
                </Box>
              </Box>
            ) : (
              label
            )
          }
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value, e)}
          value={value}
          color="primary"
          sx={customStyle}
          inputProps={{ maxLength, style: inputstyle }}
          disabled={disabled}
          // required={isRequired}
          inputRef={inputRef}
        />
      )}
      <HError errors={errors} touched={touched} name={name} />
    </div>
  );
}
