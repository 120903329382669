  //  @ts-ignore
import questionnaireImg from "../assets/images/questionnaireImg.png";
import { black500 } from "../utility/colorCode";

const HNoQuestions = ({
  title,
  para,
  onClick,
}: {
  title?: string;
  para?: string;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <div className="d-flex justify-content-center align-item-center">
      <div>
        <div className="no-result-img mt-5">
          <img className="img-fluid" src={questionnaireImg} alt="Hire Desk" />
        </div>
        <div className="no-result-txt">
          <p
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: black500,
              font: "Inter",
              textAlign: "center",
            }}
          >
            {para ? para : "You have no questions yet."}{" "}
            <span
              className="para-md blue500 pointer"
            >
              Add Question
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HNoQuestions;
