import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRef, useState } from "react";
import Slider from "react-slick";
import SliderContent from "./SliderContent";
import { settings } from "./styles";

const HStepSlider = ({ pipelineStatus }: any) => {
  const [stepDataList] = useState([
    {
      label: "Talent game of Intelligence quotient",
      stepTitle: "Assessment",
      processing: false,
      completed: true,
    },
    {
      label: "Talent game of Intelligence quotient",
      stepTitle: "Assessment",
      processing: true,
      completed: false,
    },
    {
      label: "Talent game of Intelligence quotient",
      stepTitle: "Assessment",
      processing: false,
      completed: false,
    },
  ]);

  const sliderRef = useRef<Slider>(null);

  return (
    <>
      <div className="w-100 h-100">
        <div className="d-flex justify-content-between">
          <button
            style={{
              backgroundColor: "#FFFFFF",
              color: "black",
              width: "48px",
              border: "none",
            }}
            onClick={() => sliderRef?.current?.slickPrev()}
          >
            <ArrowBackIosIcon />
          </button>
          <div style={{ width: "calc(100% - 100px)" }}>
            <Slider className="flex-grow-1" ref={sliderRef} {...settings}>
              {pipelineStatus?.length > 0
                ? pipelineStatus.map((item: any, index: number) => (
                    <SliderContent key={index} index={index + 1} {...item} />
                  ))
                : stepDataList.map((item: any, index: number) => (
                    <SliderContent key={index} index={index + 1} {...item} />
                  ))}
            </Slider>
          </div>
          <button
            style={{
              backgroundColor: "#FFFFFF",
              color: "black",
              width: "48px",
              border: "none",
            }}
            onClick={() => sliderRef?.current?.slickNext()}
          >
            <ArrowForwardIosIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default HStepSlider;
