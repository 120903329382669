import axios from "axios";
import moment from "moment";

export const getDDLAction = async (masterId: any, setter: any, accId: any) => {
  try {
    const res = await axios.get(
      `/HireLanding/GetFilterById?id=${masterId}&accountId=${accId}`
    );
    if (res?.data) {
      const data = res?.data[0]?.details?.map((item: any) => {
        return {
          ...item,
          value: item?.intDetailsId,
          label: item?.strDisplayName,
        };
      });
      setter(data);
    }
  } catch (error) {
    setter([]);
  }
};

export const getAllCustomDDL = async (
  apiUrl: string,
  value: string,
  label: string,
  setter: any
) => {
  try {
    const res = await axios.get(apiUrl);
    const newDDL = res?.data?.map((itm: any) => ({
      ...itm,
      value: itm[value],
      label: itm[label],
    }));
    setter(newDDL);
  } catch (error) {}
};
export const YearDDL = (prevYear = 5, nextYear = 5) => {
  const prevFiveYears = +moment().format("YYYY") - prevYear;
  const nextFiveYears = +moment().format("YYYY") + nextYear;
  let yearDDLList = [];
  for (let i = prevFiveYears; i <= nextFiveYears; i++) {
    const element = i;
    yearDDLList.push({
      value: i,
      label: `${element}`,
    });
  }
  return yearDDLList;
};

type commonMessageSendHandlerType = {
  msg: string;
  setMsg: any;
  job: any;
  profileData: any;
  msgListBySenderNRevId: any;
  setMsgListBySenderNRcvId: any;
  scrollToBottomRef: any;
  getSendMsgToSingleUserByUsername: any;
};

export const commonMessageSendHandler = ({
  msg,
  setMsg,
  job,
  profileData,
  msgListBySenderNRevId,
  setMsgListBySenderNRcvId,
  scrollToBottomRef,
  getSendMsgToSingleUserByUsername,
}: commonMessageSendHandlerType) => {
  const jobId = job?.intJobMasterId || 0;
  const payload = {
    id: 0,
    accountId: profileData?.intAccountId || 0,
    senderId: profileData?.email || "",
    receiverId: job?.strLoginId || "",
    receiverGroupId: 0,
    messageType: "text",
    message: msg,
    reactBySender: null,
    reactByReceiver: null,
    isSeen: false,
    isDelete: false,
    createdAt: new Date(),
    isReceiver: true,
    receiverName: "",
    senderName: "",
    senderReact: "",
    recieverReact: "",
    connectionKEY: profileData?.connectionKEY || "",
    jobMasterId: jobId,
  };
  getSendMsgToSingleUserByUsername({
    url: `/ChattingApp/SendMessageToSingleUserByUsername`,
    method: "post",
    isToast: false,
    payload,
    cb: (resData: any) => {
      const newChatItem = {
        message: msg || "",
        isMe: true,
        dateAndTime: new Date(),
        key: msgListBySenderNRevId.length + 1,
      };
      setMsg("");
      setMsgListBySenderNRcvId([...msgListBySenderNRevId, newChatItem]);
      scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    },
  });
};

type commonMessageIconClickHandlerType = {
  profileData: any;
  getMsgListBySenderNRcvId: any;
  setMsgListBySenderNRcvId: any;
  messageInputRef: any;
  scrollToBottomRef: any;
  job: any;
};

export const commonMsgListBySenderNRcv = ({
  job,
  getMsgListBySenderNRcvId,
  setMsgListBySenderNRcvId,
  profileData,
  messageInputRef,
  scrollToBottomRef,
}: commonMessageIconClickHandlerType) => {
  const jobId = job?.intJobMasterId || 0;
  getMsgListBySenderNRcvId({
    url: `/ChattingApp/GetMessageListBySenderNReceiverIdPaginationAsync?accountId=${profileData?.intAccountId}&senderId=${profileData?.email}&receiverId=${job?.strLoginId}&JobMasterId=${jobId}&receiverGroupId=0&pageNo=0&pageSize=1000`,
    method: "get",
    isToast: false,
    cb: (resData: any) => {
      setMsgListBySenderNRcvId(
        resData?.map((itm: any, index: number) => {
          return {
            ...itm,
            message: itm?.message || "",
            isMe: itm?.isReceiver ? false : true,
            dateAndTime: itm?.createdAt,
            key: index,
          };
        })
      );
      setTimeout(() => {
        messageInputRef?.current?.focus();
        scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });
      });
    },
  });
};

export const blockCandidateApi = (
  requestToServer: (data: reqDataType) => void,
  payload: BlockCandidateApiPayload,
  callback: (res: [] | {} | any) => void
) => {
  requestToServer({
    url: `/HireEvaluation/BlockCandidate`,
    method: "post",
    payload,
    cb: callback,
    isToast: true,
    successMessage: "Candidate Blocked Successfully",
    errorMessage: "Failed to block candidate",
  });
};

export const shortlistedRemarksApi = (
  requestToServer: (data: reqDataType) => void,
  payload: ShortlistedRemarksApiPayload,
  callback: (res: [] | {} | any) => void
) => {
  requestToServer({
    url: `/HireEvaluation/ShortlistedRemarks`,
    method: "put",
    payload,
    cb: callback,
    isToast: true,
    successMessage: "Data saved Successfully",
    errorMessage: "Data save failed",
  });
};

export const manpowerRequistionGetByIdApi = (
  requestToServer: (data: reqDataType) => void,
  id: number,
  callback: (res: ManpowerRequisitionApiResponse) => void
) => {
  requestToServer({
    url: `/HireLanding/ManpowerRequistionGetById?Id=${id}`,
    method: "get",
    cb: callback,
    isToast: false,
    successMessage: "Data loaded Successfully",
    errorMessage: "Data loading failed",
  });
};

export const accountConfigDetailApi = (
  requestToServer: (data: reqDataType) => void,
  accountId: number,
  callback: (res: AccountConfigDetailApiResponse) => void
) => {
  requestToServer({
    url: `/AccountConfig?accountId=${accountId}`,
    method: "get",
    cb: callback,
    isToast: false,
    successMessage: "Data loaded Successfully",
    errorMessage: "Data loading failed",
  });
};

export const preEmploymentAssessmentTestDetailApi = (
  requestToServer: (data: reqDataType) => void,
  id: number,
  callback: (res: PreEmploymentAssessmentTestDetailApiResponse) => void
) => {
  requestToServer({
    url: `/HireEvaluation/PreEmploymentAssessmentTest?id=${id}`,
    method: "get",
    cb: callback,
    isToast: false,
    successMessage: "Data loaded Successfully",
    errorMessage: "Data loading failed",
  });
};

export const preEmploymentAssessmentTestApi = (
  requestToServer: (data: reqDataType) => void,
  payload: PreEmploymentAssessmentTestApiPayload,
  callback: (res: [] | {} | any) => void
) => {
  requestToServer({
    url: `/HireEvaluation/PreEmploymentAssessmentTest`,
    method: "post",
    payload,
    cb: callback,
    isToast: true,
    successMessage: "Data Saved Successfully",
    errorMessage: "Failed to save data",
  });
};

export const removeFromShortlistApi = (
  requestToServer: (data: reqDataType) => void,
  payload: RemoveFromShortlistApiPayload,
  callback: (res: [] | {} | any) => void
) => {
  requestToServer({
    url: `/HireEvaluation/RemoveFromShortlist`,
    method: "put",
    payload,
    cb: callback,
    isToast: true,
    successMessage: "Removed from shortlist Successfully",
    errorMessage: "Failed to removed from shortlist",
  });
};

export const getJobCreateDataApi = (
  requestToServer: (data: reqDataType) => void,
  callback: (res: GetJobCreateData) => void
) => {
  requestToServer({
    url: `/JobCRUD/JobCreateData`,
    method: "get",
    cb: callback,
    isToast: false,
    successMessage: "Data loaded Successfully",
    errorMessage: "Data loading failed",
  });
};
